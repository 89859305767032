// CSSインポート
import "../scss/app.scss";

// JSインポート
import "bootstrap";
import "slick-carousel";

/**
 * 画面の横幅に応じてmeta viewportの値を動的に変更するjs（要jquery）
 *
 * window.screen.widthの値を見て$("meta[name='viewport']").attr("content", "xxxxxxxxxx")で設定
 */
$(window).on("load", function () {
  var wsw = window.screen.width;
  if (wsw <= 768) {
    //デバイス横幅768以下
    $("meta[name='viewport']").attr("content", "width=750,shrink-to-fit=no");
  } else {
    //それ以外
    $("meta[name='viewport']").attr("content", "width=1600,shrink-to-fit=no");
  }
});

$(function () {
  $('a[href^="#"]').on("click", function () {
    let speed = 500;
    let href = $(this).attr("href");
    let target = $(href == "#" || href == "" ? "html" : href);
    let position = target.offset().top - 106;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  });
  $(".slider-nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    focusOnSelect: true,
  });

  $(".item-img").on("click", function () {
    $(".item-img").removeClass("active");
    $(this).addClass("active");
    let content = $(this).attr("data-href");
    $(".item-detail__content").removeClass("active");
    $("#" + content).addClass("active");
    $(".slider-for").slick("unslick");
    $(".slider-nav").slick("unslick");
    sliderInit();
  });

  $("#form-server .server--item").on("click", function () {
    $("#form-server .server--item").removeClass("active");
    $(this).addClass("active");
  });
  $("#form-color .server--item").on("click", function () {
    $("#form-color .server--item").removeClass("active");
    $(this).addClass("active");
  });
  $("#form-delivery .server--item").on("click", function () {
    $("#form-delivery .server--item").removeClass("active");
    $(this).addClass("active");
  });

  $("#form-server .server--item").on("click", function () {
    let amount = parseInt($(this).attr("data-amount"), 10);
    $("#total-amount").attr("data-amount-1", amount);
    calcAmount();
    if ($(this).attr("data-id") == "4") {
      $("#form-color img").attr("src", "/user_data/dist/images/form_color_2.png");
      $("#form-delivery").css("display", "none");
    } else if ($(this).attr("data-id") == "1") {
      $("#form-color img").attr("src", "/user_data/dist/images/form_color_3.png");
      $("#form-delivery").css("display", "block");
    } else if ($(this).attr("data-id") == "2") {
      $("#form-color img").attr("src", "/user_data/dist/images/form_color_4.png");
      $("#form-delivery").css("display", "block");
    } else {
      $("#form-color img").attr("src", "/user_data/dist/images/form_color_1.png");
      $("#form-delivery").css("display", "block");
    }
  });
  $("#form-delivery .server--item").on("click", function () {
    let amount = parseInt($(this).attr("data-amount"), 10);
    $("#total-amount").attr("data-amount-2", amount);
    calcAmount();
  });
});

function sliderInit() {
  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  });
  $(".slider-nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    focusOnSelect: true,
  });
}

function calcAmount() {
  let amount_1 = parseInt($("#total-amount").attr("data-amount-1"), 10);
  let amount_2 = parseInt($("#total-amount").attr("data-amount-2"), 10);
  if (amount_1 == 3280) {
    amount_2 = 0;
  }
  $("#total-amount").text((amount_1 + amount_2).toLocaleString());
}